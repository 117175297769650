.conatiner {
  padding: 1rem;
}

.search-container {
  display: flex;
  flex-direction:row;
  margin-bottom: 2rem;
}

.card-container {
  margin-top: 1rem;
}

.cd-header {
  display: flex;
  justify-content: space-evenly;
  margin-top: 1rem;
}
.background {
  background-color: #FBFBFD;
}
.text-container {
  background: #FBFBFB;
  margin-top: 1rem;
  padding: 1rem;
}

.fab {
  background: linear-gradient(270.17deg, #A0A3E1 0.15%, #676AC0 99.87%);
}

.text-field {
  margin-bottom: 15px;
}

.b-bottom {
  border-bottom: 2px solid #F8F8F8;
}

.slot-container {
  padding: '1rem';
  display: 'flex';
  justify-content: space-between;
  
}

p {
  font-family:'Jost';
  margin-bottom: 0;
}