.App {
  text-align: center;
  font-family: 'Jost', serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Home-header {
  background: #F9F9F9;
  display: flex;
  padding: 1rem;
}

.Home-container {
  /* display: flex;
  justify-content: space-around;
  align-content: flex-start;
  align-items:flex-start;
  flex-wrap: wrap; */
  /* background-color: #F4F4F7; */
  width: 100%;
  min-height: 100vh;
  padding: 1rem;
}

.Card-model {
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  background: #FFFFFF;
  margin: 1rem 0 1rem 0;
  display: flex;
  justify-content: space-around;
  align-content: flex-start;
  align-items:flex-start;
  flex-wrap: wrap;
  padding: 1rem;
}

.Home-icon-button {
  text-align: center;
  align-items: center;
  align-content: center;
  width: 83px;
  height: 83px;
  border-radius: 42px;
  background: #FFFFFF;
  /* padding: 18px; */
  margin: 0.5rem;
}

.Home-icon-button:hover {
  background: linear-gradient(270.17deg, #A0A3E1 0.15%, #676AC0 99.87%);
}

.Home-icon-button-text {
  size: 16px;
  font-weight: 400;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
