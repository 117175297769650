.conatiner {
  padding: 1rem;
}

.search-container {
  display: flex;
  flex-direction:row;
}

.cd-header {
  display: flex;
  justify-content: space-between;
}
